import axios from 'axios'
import { isEmpty, isFunction } from 'lodash'

import { apiURLOrigin, apiURLOriginV2, apiURLOriginV4 } from 'api/common'
import constants from 'app/constants'
import { getAccessToken, makeRequestUrl, notify, showToast } from 'app/helpers'
import * as types from 'app/store/types/auth'
import { checkIfQueryPresentInURL } from 'app/utils'
import { sentryLogger } from 'sentry'

const isDevelopment = process.env.NODE_ENV === 'development'

const HttpsAgent = require('agentkeepalive').HttpsAgent
const Agent = require('agentkeepalive')

const httpAgent = new Agent({
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 600000, // active socket keepalive for 600 seconds
  freeSocketTimeout: 300000 // free socket keepalive for 300 seconds
})
const httpsAgent = new HttpsAgent()

// creating instance for later use in all requests

// axios.defaults.headers.common['Keep-Alive'] = 'timeout=15,max=1000'
const axiosInstance = axios.create({
  httpAgent,
  httpsAgent
})

const isOhApiEndpoint = (str = '') =>
  str.startsWith(apiURLOrigin) ||
  str.startsWith(apiURLOriginV2) ||
  str.startsWith(apiURLOriginV4)

// setting request interceptor, to feed mandatory headers in all requests
axiosInstance.interceptors.request.use(
  async (options) => {
    try {
      const { url, method } = options

      // make https if not
      if (url.startsWith('http://') && !isDevelopment) {
        options.url = options.url.replace('http://', 'https://')
      }

      // append limit if not present in url queries
      if (
        method === 'get' &&
        isOhApiEndpoint(url) &&
        !checkIfQueryPresentInURL(url, 'search') &&
        !checkIfQueryPresentInURL(url, 'limit')
      ) {
        // get page limit from localstorage, fallbacking to 25 if null
        const numberOfRows = 25

        options.url = makeRequestUrl(options.url, { limit: numberOfRows })
      }

      const headers = {
        ...options.headers
      }

      if (!Object.prototype.hasOwnProperty.call(headers, 'Content-Type')) {
        headers['Content-Type'] = 'application/json'
      }

      if (
        isOhApiEndpoint(options.url) &&
        !Object.prototype.hasOwnProperty.call(headers, 'Authorization')
      ) {
        const accessToken = getAccessToken()
        if (accessToken) {
          headers.Authorization = `Bearer ${accessToken}`
        }
        headers.source = 'alfred'
      }

      options.headers = headers
      return options
    } catch (error) {
      console.log('error in getting token :- ', error)
    }
  },
  (error) => {
    console.log('error in request handling :- ', error)
    return Promise.reject(error)
  }
)

// setting response interceptor, mainly for logging errors and response validation
axiosInstance.interceptors.response.use(
  (response) => {
    // validate create, update and delete method response
    const {
      config: { method, url }
    } = response

    if (
      isOhApiEndpoint(url) &&
      ['post', 'patch', 'put', 'delete'].indexOf(method) > -1
    ) {
      // mutation request, check with known backend parameter, i.e success: true
      if (
        !Object.prototype.hasOwnProperty.call(response.data, 'success') ||
        response.data.success
      ) {
        // its a valid response
        return response
      } else {
        // got something unexpected from server even if response status is proper
        return Promise.reject(
          new Error(
            response.data.message || 'Whoopsie-Daisy!! Something went wrong! 😞'
          )
        )
      }
    }

    return response
  },
  (error) => {
    showToast(`Oops, Error getting data`, constants.ERROR)
    console.log('Error Traceback: ', error.toJSON())
    sentryLogger(error)
    let errorMessage = !isEmpty(error?.message)
      ? error.message
      : constants.ERROR_MESSAGE

    if (error.response) {
      // some server side error
      const errorStatusCode = error.response.status
      const errorType = error.response?.data?.error_type

      if (errorStatusCode === 502 || errorStatusCode === 503) {
        // server unavailable
        errorMessage = 'Server is unavailable 😞'
        notify(`${errorMessage}`, '', 'info')
      } else if (errorStatusCode >= 400 && errorStatusCode <= 500) {
        if (errorStatusCode === 401) {
          // FIXME: getting store reference from global to prevent circular import for now
          if (window.reduxStore && isFunction(window.reduxStore.dispatch)) {
            window.reduxStore.dispatch({ type: types.LOGOUT_START })
          }
        } else if (errorStatusCode === 498 && errorType) {
          errorMessage = `${errorType}-${error.response.data.message}`
        } else {
          const successResponse = error.response.data.success

          if (!successResponse) {
            errorMessage = error.response.data.message

            if (isEmpty(errorMessage)) {
              errorMessage = 'Whoopsie-Daisy!! Something went wrong 😞'

              if (Array.isArray(errorMessage)) {
                errorMessage = errorMessage.join(', ')
              }
            }
          }
        }
      } else {
        const successResponse = error.response.data.success

        if (!successResponse) {
          errorMessage = error.response.data.message

          if (isEmpty(errorMessage)) {
            errorMessage = 'Whoopsie-Daisy!! Something went wrong 😞'

            if (Array.isArray(errorMessage)) {
              errorMessage = errorMessage.join(', ')
            }
          }
        }
      }
      return Promise.reject(new Error(errorMessage))
    } else if (error.request) {
      // request initiated but no response from server, most probably bcz of network error
      notify(errorMessage, '', 'info')
    } else {
      notify(errorMessage, '', 'info')
    }

    return Promise.reject(error)
  }
)

const get = async (url, options = {}) => {
  try {
    const response = await axiosInstance.get(url, options)
    return response
  } catch (err) {
    throw err
  }
}

const post = async (url, data, options = {}) => {
  try {
    const response = await axiosInstance.post(url, data, options)
    return response
  } catch (err) {
    throw err
  }
}

const put = async (url, data, options = {}) => {
  try {
    const response = await axiosInstance.put(url, data, options)
    return response
  } catch (err) {
    throw err
  }
}

const patch = async (url, data, options = {}) => {
  try {
    const response = await axiosInstance.patch(url, data, options)
    return response
  } catch (err) {
    throw err
  }
}

const remove = async (url, options = {}, data) => {
  try {
    const response = await axiosInstance.delete(url, { ...options, data: data })
    return response
  } catch (err) {
    throw err
  }
}

export { get, post, put, patch, remove, axiosInstance }
