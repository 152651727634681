import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'

// import { persistStore, persistReducer } from 'redux-persist'

// import storage from 'redux-persist/lib/storage'

import { appReducer } from './reducers/app'
import { authReducer } from './reducers/auth'
import { classEntityReducer } from './reducers/classEntity'
import { commonReducer } from './reducers/common'
import { communicationEntityReducer } from './reducers/communicationEntity'
import { communityEntityReducer } from './reducers/communityEntity'
import { demoSchedulingReducer } from './reducers/demoScheduling'
import { eventEntityReducer } from './reducers/eventEntity'
import { mockTestEntityReducer } from './reducers/mockTestEntity'
import { ordersEntityReducer } from './reducers/ordersEntity'
import { paymentEntityReducer } from './reducers/paymentEntity'
import { spaceEntityReducer } from './reducers/spaceEntity'
import { userEntityReducer } from './reducers/userEntity'
import rootSaga from './sagas'

const filterActionPropagation = () => (next) => (action) => {
  if (!action.type.startsWith('HANDLE_')) return next(action)
}

const sagaMiddleware = createSagaMiddleware()

const middlewares = [thunkMiddleware, sagaMiddleware, filterActionPropagation]

const rootReducer = combineReducers({
  appReducer,
  classEntityReducer,
  communicationEntityReducer,
  communityEntityReducer,
  mockTestEntityReducer,
  demoSchedulingReducer,
  eventEntityReducer,
  paymentEntityReducer,
  spaceEntityReducer,
  userEntityReducer,
  authReducer,
  commonReducer,
  ordersEntityReducer
})

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
)

// finally run saga
sagaMiddleware.run(rootSaga)

// const persistor = persistStore(store)

// setting store in global to use it globally
window.reduxStore = store

// export { store, persistor }
export { store }
