import React from 'react'

import * as styles from './styles'
const FlagChip = ({ flag }) => {
  const { value, icon } = flag
  return (
    <styles.FlagBox>
      <img src={icon} height={14} width={14} />
      <styles.FlagText>{value}</styles.FlagText>
    </styles.FlagBox>
  )
}

export default FlagChip
