import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React from 'react'

import { NotFoundBlock } from 'app/pages/Dashboard/components/NotFoundBlock'

import * as styles from './styles'

import { TitleDescription } from '../../../../../components/TitleDescription'

export const Interactions = (props) => {
  const { interactionsData } = props

  const LatestInteractionSummary = (
    <styles.InteractionSummaryContainer>
      {interactionsData?.data?.[0]?.latest_interaction_summary?.map(
        (summary) => (
          <>
            <styles.BoldSpan>{summary?.topic}</styles.BoldSpan>:{' '}
            {`${summary?.summary}`} <br />
          </>
        )
      )}
    </styles.InteractionSummaryContainer>
  )

  return !isEmpty(interactionsData?.data) ? (
    <styles.Body>
      <styles.InfoBlock>
        <TitleDescription
          heading="Last Interaction Date"
          description={dayjs(
            interactionsData?.data[0]?.latest_interaction_date
          ).format('DD MMM, YYYY')}
        />
      </styles.InfoBlock>
      <styles.InfoBlock>
        <TitleDescription
          heading="Last Interaction Summary"
          description={LatestInteractionSummary}
        />
      </styles.InfoBlock>
    </styles.Body>
  ) : (
    <NotFoundBlock message="Oh no! No interactions with parent." />
  )
}
