import styled from 'styled-components'

export const Body = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .MuiDivider-root {
    width: 100%;
  }
`

export const InfoBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 10px;
`
export const InteractionSummaryContainer = styled.div`
  margin-top: 2px;
  font-family: AvenirMedium;
`
export const BoldSpan = styled.span`
  font-family: AvenirDemi;
`
