import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ isRightAligned }) => (isRightAligned ? 'row-reverse' : 'row')};
  align-items: flex-end;
  margin-bottom: 0.25rem;
  margin-right: ${({ isRightAligned }) => (!isRightAligned ? '15%' : '0%')};
  margin-left: ${({ isRightAligned }) => (isRightAligned ? '15%' : '0%')};
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0rem;
`

export const TextStyles = {
  fontSize: '0.625rem',
  color: 'rgba(0, 0, 0, 0.56)'
}

export const avatarImageStyles = {
  height: '2rem',
  width: '2rem',
  borderRadius: '50%',
  flexShrink: 0
};

export const avatarTextStyles = {
  width: '2rem',
  height: '2rem',
  fontSize: '1rem',
  fontWeight: '700',
  borderRadius: '50%',
  backgroundColor: '#F5A066',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  flexShrink: 0
}