export const FETCH_BULK_MESSAGING_DATA = 'FETCH_BULK_MESSAGING_DATA'
export const FETCH_BULK_MESSAGING_DATA_SUCCESS =
  'FETCH_BULK_MESSAGING_DATA_SUCCESS'

export const FETCH_REPORT_CARD_DATA = 'FETCH_REPORT_CARD_DATA'
export const FETCH_REPORT_CARD_DATA_SUCCESS = 'FETCH_REPORT_CARD_DATA_SUCCESS'

export const HANDLE_UPDATE_SELECTED_MESSAGE_TEMPLATE =
  'HANDLE_UPDATE_SELECTED_MESSAGE_TEMPLATE'
export const UPDATE_SELECTED_MESSAGE_TEMPLATE =
  'UPDATE_SELECTED_MESSAGE_TEMPLATE'

export const HANDLE_UPDATE_MESSAGE_TEMPLATE_PARAMETER =
  'HANDLE_UPDATE_MESSAGE_TEMPLATE_PARAMETER'
export const UPDATE_MESSAGE_TEMPLATE_PARAMETER =
  'UPDATE_MESSAGE_TEMPLATE_PARAMETER'
export const HANDLE_ON_BULK_MESSAGES_SEND = 'HANDLE_ON_BULK_MESSAGES_SEND'
export const HANDLE_BULK_MESSAGES_CREATE_VALIDATION =
  'HANDLE_BULK_MESSAGES_CREATE_VALIDATION'
export const HANDLE_UPDATE_USER_TYPE = 'HANDLE_UPDATE_USER_TYPE'
export const UPDATE_USER_TYPE = 'UPDATE_USER_TYPE'
export const UPDATE_SEND_MESSAGE_MODAL_STATE = 'UPDATE_SEND_MESSAGE_MODAL_STATE'
export const UPDATE_CREATE_MESSAGE_TEMPLATE_MODAL_STATE =
  'UPDATE_CREATE_MESSAGE_TEMPLATE_MODAL_STATE'
export const UPDATE_DELIVERY_STATS_MODAL_STATE =
  'UPDATE_DELIVERY_STATS_MODAL_STATE'
export const HANDLE_FETCH_USERS = 'HANDLE_FETCH_USERS'
export const UPDATE_USERS = 'UPDATE_USERS'
export const UPDATE_EXCLUDED_USERS = 'UPDATE_EXCLUDED_USERS'
export const UPDATE_INCLUDED_USERS = 'UPDATE_INCLUDED_USERS'

export const HANDLE_FETCH_DELIVERY_STATS_DATA =
  'HANDLE_FETCH_DELIVERY_STATS_DATA'
export const FETCH_DELIVERY_STATS_DATA = 'FETCH_DELIVERY_STATS_DATA'
export const FETCH_DELIVERY_STATS_DATA_SUCCESS =
  'FETCH_DELIVERY_STATS_DATA_SUCCESS'
export const UPDATE_DELIVERY_TYPE = 'UPDATE_DELIVERY_TYPE'
export const UPDATE_FIELD_ERRORS = 'UPDATE_FIELD_ERRORS'
export const RESET_BULK_MESSAGING_CREATION_DATA =
  'RESET_BULK_MESSAGING_CREATION_DATA'
export const UPDATE_TARGET_AUDIENCE_SELECTION_MODE =
  'UPDATE_TARGET_AUDIENCE_SELECTION_MODE'
export const UPDATE_BULK_MESSAGING_USER_FILTERS =
  'UPDATE_BULK_MESSAGING_USER_FILTERS'
export const UPDATE_BULK_MESSAGING_SEND_API_PROGRESS_STATUS =
  'UPDATE_BULK_MESSAGING_SEND_API_PROGRESS_STATUS'

// Interactions
export const FETCH_INTERACTION_HISTORY_DATA = 'FETCH_INTERACTION_HISTORY_DATA'
export const FETCH_INTERACTION_HISTORY_DATA_SUCCESS =
  'FETCH_INTERACTION_HISTORY_DATA_SUCCESS'
export const UPDATE_INTERACTION_HISTORY_DATA = 'UPDATE_INTERACTION_HISTORY_DATA'
export const UPDATE_INTERACTION_HISTORY_DATA_SUCCESS =
  'UPDATE_INTERACTION_HISTORY_DATA_SUCCESS'

//Tickets
export const FETCH_TICKETS_DATA = 'FETCH_TICKETS_DATA'
export const FETCH_TICKETS_DATA_SUCCESS = 'FETCH_TICKETS_DATA_SUCCESS'
export const UPDATE_TICKET_DATA = 'UPDATE_TICKET_DATA'
export const UPDATE_TICKET_DATA_SUCCESS = 'UPDATE_TICKET_DATA_SUCCESS'

//Chat
export const FETCH_TEACHER_LIST_DATA = 'FETCH_TEACHER_LIST'
export const FETCH_TEACHER_LIST_DATA_SUCCESS = 'FETCH_STATES_SUCCESS'
export const FETCH_STUDENT_LIST_DATA = 'FETCH_STUDENT_LIST'
export const FETCH_STUDENT_LIST_DATA_SUCCESS = 'FETCH_STUDENT_LIST_SUCCESS'
export const FETCH_CHAT_MESSAGES_DATA = 'FETCH_CHAT_MESSAGES'
export const FETCH_CHAT_MESSAGES_DATA_SUCCESS = 'FETCH_CHAT_MESSAGES_SUCCESS'
export const SET_CHAT_MESSAGES_HEADER_DATA = 'SET_CHAT_MESSAGES_HEADER_DATA'
