export const selectTeacherListData = (state) =>
  state.communicationEntityReducer.teacherListData

export const selectStudentListData = (state) =>
  state.communicationEntityReducer.studentListData

export const selectChatMessagesData = (state) =>
  state.communicationEntityReducer.chatMessagesData

export const selectChatMessagesHeaderData = (state) =>
  state.communicationEntityReducer.chatMessagesHeaderData