/* eslint-disable react/prop-types */
import CheckCircle from '@material-ui/icons/CheckCircle'
import dayjs from 'dayjs'
import React from 'react'

import constants from 'app/constants'
import { eventEmitter } from 'app/helpers'
import { batchTypesMap, levelMap } from 'app/pages/Class/Inventory/constants'
import { composeBatchDetails } from 'app/pages/Class/Inventory/transformers'

import {
  Wrapper,
  Body,
  Content,
  Footer,
  Details,
  TimeSlots,
  SlotsLeft,
  ExtraInfo,
  Coins
} from './styles'

export const ScheduleCard = ({ event }) => {
  const [isSelected, setIsSelected] = React.useState(false)
  const isDisabled =
    event.extendedProps.vacancy <= 0 || event.extendedProps.isDisabled
  const { centre, course, teacher, level } = event.extendedProps
  // const category = (course.subjects || [])[0]?.name || ''
  const category = (course.subjects || [])[0]?.subject_category.name || ''

  const { subjectsName } = composeBatchDetails(event.extendedProps)

  React.useEffect(() => {
    if (!event.id) return
    const handleSelection = (batch) => {
      if (event.id === batch.uuid) {
        setIsSelected((isSelected) => !isSelected)
      }
    }
    eventEmitter.addListener(constants.BATCH_SELECTION, handleSelection)
    return () => {
      eventEmitter.removeListener(constants.BATCH_SELECTION, handleSelection)
    }
  }, [event.id])

  return (
    <Wrapper
      id={`ScheduleCard-${event.id}`}
      category={category || 'fallback'}
      isSelected={isSelected}
      // isDisabled={isDisabled}
      onClick={() => {
        // if (isDisabled) return
        eventEmitter.emit(constants.BATCH_SELECTION, event._def.extendedProps)
      }}>
      {isSelected && (
        <CheckCircle htmlColor="rgba(16, 208, 139)" className="selected" />
      )}
      <Body>
        <TimeSlots>
          {event.extendedProps.timings.map((timing, index) => {
            timing = timing.replaceAll(' 0', ' ')
            const [day, ...time] = timing.split(' ')
            const isSameDay =
              day?.toUpperCase() ===
              dayjs(event.start).format('ddd').toUpperCase()
            return (
              <Content key={`${timing}-${index}`} highlight={isSameDay}>
                {isSameDay ? `${time.join(' ')}` : timing}
              </Content>
            )
          })}
        </TimeSlots>
        <ExtraInfo>
          {event.extendedProps.type !== batchTypesMap.exploration ? (
            <Content subheading>
              {event.extendedProps.type === batchTypesMap.excel
                ? `${event.extendedProps.coins_per_month} Coins` || '0 Coins'
                : `${event.extendedProps.coins_per_session} Coins` || '0 Coins'}
            </Content>
          ) : null}
          {subjectsName && <Content highlight>{subjectsName}</Content>}
          {level && <Content subContent>{levelMap[level]}</Content>}
          {teacher && (
            <Content
              css={`
                // width: 67%;
              `}>
              {teacher.full_name}
            </Content>
          )}
          {/* {course.max_age > -1 && course.min_age > -1 ? ( */}
          <Content>
            {course.standards.map((item) => item.short_code).join(',')}
          </Content>
          {/* ) : null} */}
        </ExtraInfo>
        <Details
          onClick={(e) => {
            eventEmitter.emit(
              constants.BATCH_DETAILS_MODAL,
              event.extendedProps
            )
            e.stopPropagation()
          }}>
          <Content>Details</Content>
          <span className="fc-icon fc-icon-chevron-right"></span>
        </Details>
        <SlotsLeft>
          <Content>
            {event.extendedProps.vacancy > 0 ? event.extendedProps.vacancy : 0}{' '}
            left
          </Content>
        </SlotsLeft>
      </Body>
      <Footer>{centre?.name || ''}</Footer>
    </Wrapper>
  )
}
