import React from 'react'
import * as styles from './styles'
import UnreadCount from 'app/components/generic/UnreadCount'
import Avatar from 'app/components/generic/Avatar'
import PillList from 'app/components/generic/PillList'
import IconText from 'app/components/generic/IconText'
import { formatTimestamp } from '../../helpers'

const StudentListCard = (props) => {

  const {
    name,
    thumbnailUrl,
    timestamp,
    pillData,
    iconURL,
    lastMessage,
    lastMessageStatus,
    unreadCount,
    color,
    onClick,
    isSelected,
    lastListItem
  } = props

  return (
    <div onClick={onClick}>
      <styles.SelectedCardContainer isSelected={isSelected} lastListItem={lastListItem}>
        <styles.InnerCardContainer>
          <Avatar
            thumbnailUrl={thumbnailUrl}
            name={name}
            avatarTextStyles={styles.avatarTextStyles}
          />
          <styles.CardDetails>
            <styles.TopRow>
              <styles.Title>{name.toLowerCase()}</styles.Title>
              <styles.Time>{formatTimestamp(timestamp)}</styles.Time>
            </styles.TopRow>
            <PillList data={pillData} isSelected={isSelected} truncationLength={20} />
            <styles.BottomRow>
              <IconText
                url={iconURL}
                text={lastMessage?.length > 42 ? `${lastMessage.substring(0, 42).trim()}...` : lastMessage}
                isDisplayIcon={lastMessageStatus === 'read' ? true : false}
                customStyle={styles.TextStyles}
              />
              <UnreadCount unreadCount={unreadCount} />
            </styles.BottomRow>
          </styles.CardDetails>
        </styles.InnerCardContainer>
      </styles.SelectedCardContainer>
    </div >
  )
}

export default StudentListCard