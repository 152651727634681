import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.75rem 1rem 0.75rem 1rem;
  background-color:  ${({ authorType }) => (authorType === 'admin' ? '#EF5C34' : authorType === 'parent' ? '#F6F6F6' : '#FFE1D9')};
  color: ${({ authorType }) => (authorType === 'admin' ? 'white' : 'black')};
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
`

export const Icon = styled.img`
  height: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
`

export const Text = styled.div`
  margin-right: 0.25rem;
`