import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import EmptyDataIcon from 'app/components/generic/EmptyDataIcon'
import Loading from 'app/components/generic/Loading'
import SearchComponent from 'app/components/generic/SearchComponent'
import constants from 'app/constants'
import {
  fetchChatMessagesData,
  fetchStudentListData,
  setChatMessagesHeaderData
} from 'app/store/actions/communicationEntity'
import { selectStudentListData } from 'app/store/selectors/communicationEntity/chat'

import * as styles from './styles'

import { checkMark } from '../../constants'
import { sortDataByLastDate, getChatHeaderData } from '../../helpers'
import StudentListCard from '../StudentListCard'

const StudentList = () => {
  const dispatch = useDispatch()
  // const [loading, setLoading] = React.useState(false)
  const [selectedChat, setSelectedChat] = React.useState(
    localStorage.getItem('SELECTED_CHAT')
  )
  const studentListData = useSelector(selectStudentListData)
  const data = studentListData?.data
  const loading = studentListData?.isLoading
  const sortedData = sortDataByLastDate(data)
  const selectedTeacher = localStorage.getItem('SELECTED_TEACHER')

  // const updateLoadingValue = (value) => setLoading(value)

  const handleStudentCardClick = (event, item) => {
    setSelectedChat(item.uuid)
    // localStorage.setItem('SELECTED_CHAT', item.uuid)
    dispatch(
      fetchChatMessagesData(
        {
          queries: {
            chat_uuid: item.uuid,
            page_size: 1000
          }
        },
        false,
        false,
        constants.CHAT_MESSAGES
      )
    )
    const chatHeaderData = getChatHeaderData(item)
    dispatch(
      setChatMessagesHeaderData(chatHeaderData, constants.CHAT_MESSAGES_HEADER)
    )
  }

  return (
    <styles.StudentListContainer>
      <styles.HeaderContainer>
        <styles.Heading>student's list</styles.Heading>
        <SearchComponent
          label={'search by parent, child name or number'}
          fetchDataAction={fetchStudentListData}
          initialQueries={{
            teacher_uuid: selectedTeacher,
            page_size: 1000
          }}
          constants={constants.STUDENT_LIST}
          // updateLoadingValue={updateLoadingValue}
        />
      </styles.HeaderContainer>
      <styles.BodyContainer>
        {loading ? (
          <Loading />
        ) : sortedData.length > 0 ? (
          sortedData.map((item, index) => (
            <StudentListCard
              uuid={item?.uuid}
              name={item?.details?.teacher_display_meta?.chat_title}
              thumbnailUrl={item?.details?.teacher_display_meta?.chat_thumbnail}
              timestamp={item?.last_message?.created}
              pillData={item?.details?.teacher_display_meta?.chat_labels}
              lastMessage={item?.last_message?.content?.body}
              lastMessageStatus={item?.last_message?.status}
              iconURL={checkMark}
              unreadCount={item.unread_message_count}
              color={styles.AvatarColor}
              onClick={(event) => handleStudentCardClick(event, item)}
              isSelected={item.uuid === selectedChat}
              lastListItem={index === sortedData.length - 1}
            />
          ))
        ) : (
          <EmptyDataIcon message={'students'} />
        )}
      </styles.BodyContainer>
    </styles.StudentListContainer>
  )
}

export default StudentList
