export const setAccessTokenKey = (type) => {
  return `${type.toUpperCase()}-TOKEN-${process.env.REACT_APP_CUSTOM_NODE_ENV.toUpperCase()}`
}

const access_token_key = setAccessTokenKey('OH-ACCESS')
const refresh_token_key = setAccessTokenKey('OH-REFRESH')
const refresh_last_updated_token_key = setAccessTokenKey(
  'OH-REFRESH-LAST-UPDATED'
)
const user_number = setAccessTokenKey('OH-USER-NUMBER')

export default {
  ACCESS_TOKEN_KEY: access_token_key, //'OPENHOUSE-ACCESS-TOKEN-KEY',
  ACTIVE: 'active',
  ADMIN: 'admin',
  ADMIN_LABEL: 'Admin',
  ATTENDANCE: 'attendance',
  ATTENDANCE_LABEL: 'Attendance',
  ATTACH_TRIBE: 'attachTribe',
  BATCH: 'batch',
  BATCH_LABEL: 'Batch',
  BOARD: 'board',
  BOARD_LABEL: 'Board',
  BATCH_SELECTION: 'inventory/BATCH_SELECTION',
  BATCH_DETAILS_MODAL: 'inventory/BATCH_DETAILS_MODAL',
  BATCH_RESELECTION: 'inventory/BATCH_RESELECTION',
  BULK_MESSAGING: 'bulkMessaging',
  BULK_MESSAGING_MESSAGE_VALID: 'bulkMessaging/BULK_MESSAGING_MESSAGE_VALID',
  BULK_MESSAGING_LABEL: 'Bulk Messaging',
  CENTRE: 'centre',
  CLOSE_BULK_MESSAGING_MODAL: 'bulkMessaging/CLOSE_BULK_MESSAGING_MODAL',
  CENTRE_LABEL: 'Centre',
  CHANGE_BATCH: 'CHANGE_BATCH',
  CLUB: 'club',
  CLUB_LABEL: 'Club',
  CLUB_REGISTRATION: 'clubRegistration',
  CLUB_REGISTRATION_LABEL: 'Club Registration',
  CANCELED_MEMBER: 'canceledMember',
  CANCELED_MEMBER_LABEL: 'Cancelled Member',
  CONVERT_N: 'convertN',
  CONVERTED: 'converted',
  COURSE: 'course',
  COURSE_LABEL: 'Course',
  CLOSE_MODAL_FORM: 'CLOSE_MODAL_FORM',
  CUSTOM_PRICING: 'customPricing',
  CUSTOM_PRICING_LABEL: 'Custom Pricing',
  CREATED: 'created',
  DARK: 'dark',
  DATA_DELETED: 'Data Deleted!',
  DATA_SAVED: 'Data Saved! 🥳',
  DISPATCH: 'dispatch',
  DISPATCH_LABEL: 'Dispatch',
  DUE: 'due',
  DUE_LABEL: 'Due Non-member',
  DUE_MEMBER: 'dueMember',
  DUE_MEMBER_LABEL: 'Due Member',
  DUE_TOPUP: 'dueTopup',
  DUE_TOPUP_LABEL: 'Due Recharge',
  DUE_RENEWAL: 'dueRenewal',
  DUE_RENEWAL_LABEL: 'Due Renewal',
  ENROLMENT: 'enrolment',
  ENROLMENT_LABEL: 'Event Enrolments',
  ERROR: 'error',
  ERROR_MESSAGE: 'Server Error Occurred!',
  EVENT: 'event',
  EVENT_LABEL: 'Event',
  EVENT_POSTER: 'event_poster',
  EVENT_REGISTRATION: 'eventRegistration',
  EVENT_REGISTRATION_LABEL: 'Event Registration',
  FILL_ALL_REQUIRED_FIELDS: 'Fill All Required Fields',
  FUTURE_ATTENDANCE: 'futureattendance',
  FUTURE_ATTENDANCE_LABEL: 'Future Attendance',
  FETCHING: 'fetching',
  IS_SCHEDULING_REQUEST_FINISHED: 'IS_SCHEDULING_REQUEST_FINISHED',
  INVENTORY_SCHEDULES: 'inventorySchedules',
  INDEPENDENT: 'independent',
  INDEPENDENT_LABEL: 'Independent',
  INDEPENDENT_SUMMARY: 'independentSummary',
  INDEPENDENT_SUMMARY_LABEL: 'Teacher Dispatch',
  INDEPENDENT_ONGOING: 'independentOngoing',
  INDEPENDENT_ONGOING_LABEL: 'IndependentOngoing',
  INDEPENDENT_ONGOING_SUMMARY: 'independentOngoingSummary',
  INDEPENDENT_ONGOING_SUMMARY_LABEL: 'Teacher Ongoing Earnings',
  INACTIVE_TRIBE_MEMBERSHIP: 'inactiveMembership',
  INACTIVE_TRIBE_MEMBERSHIP_LABEL: 'Inactive Membership',
  INTERACTION_HISTORY: 'interactionHistory',
  INTERACTION_HISTORY_LABEL: 'Interaction',
  INVENTORY_BOOKING_SUCCESS: 'INVENTORY_BOOKING_SUCCESS',
  INVENTORY_BOOKING_ERROR: 'INVENTORY_BOOKING_ERROR',
  INFO: 'info',
  LIGHT: 'light',
  MOMENTS: 'moments',
  MODAL_FORM_SAVE_ERROR: 'MODAL_FORM_SAVE_ERROR',
  MAKE_UP_SESSION: 'makeUpSession',
  MAKE_UP_SESSION_LABEL: 'Make-Up Session',
  EXPLORE_SESSION: 'exploreSession',
  EXPLORE_SESSION_LABEL: 'Explore Session',
  EXPLORE_ENROLMENT: 'exploreEnrolment',
  EXPLORE_ENROLMENT_LABEL: 'Explore Enrolments',
  EXCEL_ENROLMENT: 'excelEnrolment',
  EXCEL_ENROLMENT_LABEL: 'Excel Enrolments',
  MEMBERSHIP: 'membership',
  MEMBERSHIP_LABEL: 'Membership',
  MOCK_TEST_REPORT_CARD: 'mockTestReportCard',
  MOCK_TEST_REPORT_CARD_LABEL: 'Students Mock Test Reports',
  MOCK_TEST_THUMBNAIL: 'mockThumbnail',
  MOCK_TEST_PDF: 'mockPDF',
  NETWORK_ERROR: 'Network Error',
  OPENBOOK: 'openbook',
  OPENDAY_SCHEDULE: 'opendaySchedule',
  OPENDAY_SCHEDULE_LABEL: 'openday schedule',
  OPENDAY_SLOTS: 'opendaySlots',
  OPENDAY_SLOTS_LABEL: 'openday slots',
  OPPORTUNITIES: 'opportunities',
  OPPORTUNITIES_LABEL: 'Showcases & competitions',
  OTHERS: 'others',
  PAPER: 'paper',
  PAPER_LABEL: 'Paper',
  PRICING: 'pricing',
  PRICING_LABEL: 'Pricing',
  PAGE_NO: 'PAGE_NO',
  PAID: 'paid',
  PAID_LABEL: 'Paid Non-Member',
  PAID_MEMBER: 'paidMember',
  PAID_MEMBER_LABEL: 'Paid Member',
  PAID_TOPUP: 'paidTopup',
  PAID_TOPUP_LABEL: 'Paid Recharge',
  PAID_RENEWAL: 'paidRenewal',
  PAID_RENEWAL_LABEL: 'Paid Renewal',
  PAUSED: 'paused',
  PAUSED_MEMBER: 'pausedMember',
  PAUSED_MEMBER_LABEL: 'Paused Member',
  PAST_ATTENDANCE: 'pastattendance',
  PAST_ATTENDANCE_LABEL: 'Past Attendance',
  PARENT: 'parent',
  PARENT_LABEL: 'Parent',
  PRORATA: 'prorata',
  PRORATA_LABEL: 'Pro Rata',
  QUEUED: 'queued',
  REMOVE_SELECTED_ROWS: 'REMOVE_SELECTED_ROWS',
  REFETCHING_STOP: 'REFETCHING_STOP',
  REFERRAL_CODE: 'referralCode',
  REFERRAL_CODE_LABEL: 'Referral Code',
  REFRESH_TOKEN_KEY: refresh_token_key, //'OPENHOUSE-REFRESH-TOKEN-KEY',
  REFRESH_TOKEN_LAST_UPDATED: refresh_last_updated_token_key, //'OPENHOUSE-REFRESH-TOKEN-LAST-UPDATED',
  REMOVE_ALL_FILTERS: 'REMOVE_ALL_FILTERS',
  REMOVED: 'removed',
  ROOM: 'room',
  ROOM_LABEL: 'Room',
  REPORT_CARD: 'reportCard',
  REPORT_CARD_LABEL: 'Student Reports',
  STUDENT: 'student',
  STUDENT_LABEL: 'Student',
  STANDARD: 'standard',
  STANDARD_LABEL: 'Standard',
  STUDENT_SUMMARY: 'studentSummary',
  STUDENT_SUMMARY_LABEL: 'Student Summary',
  SUBJECT: 'subject',
  SUBJECT_LABEL: 'Subject',
  SUBJECT_CATEGORY: 'subjectCategory',
  SUBJECT_CATEGORY_LABEL: 'Subject Category',
  SUCCESS: 'success',
  SUPERPROVIDER: 'superprovider',
  SUPERPROVIDER_LABEL: 'Superprovider',
  SUPERPROVIDER_SUMMARY: 'superproviderSummary',
  SUPERPROVIDER_SUMMARY_LABEL: 'Superprovider Summary',
  SCHOOL_ATTENDANCE: 'school_attendance',
  TEACHER: 'teacher',
  TEACHER_LABEL: 'Teacher',
  TEACHER_SUMMARY: 'teacherSummary',
  TEACHER_SUMMARY_LABEL: 'Teacher Summary',
  TERMINATE_ENROLMENT: 'TERMINATE_ENROLMENT',
  TICKET: 'ticket',
  TICKET_LABEL: 'Ticket',
  TRIBE: 'tribe',
  TRIBE_LABEL: 'Tribe',
  TRIAL_SESSIONS: 'trialSessions',
  TRIAL_SESSIONS_LABEL: 'Trial Sessions',
  TRIBE_SELECTION: 'tribe_selection',
  UPDATED: 'updated',
  USER: 'user',
  USER_NUMBER: user_number, //'OPENHOUSE-USER-NUMBER',
  UPCOMING: 'upcoming',
  WAITLIST_OTHERS: 'waitlistOthers',
  WAITLIST_OTHERS_LABEL: 'Other Waitlists',
  WAITLIST_CONVERTED: 'waitlistConverted',
  WAITLIST_CONVERTED_LABEL: 'Converted Members',
  WAITLIST_QUEUED: 'waitlistQueued',
  WAITLIST_QUEUED_LABEL: 'Registrations',
  WAITLIST_TRIBE_SELECTION: 'waitlistTribeSelection',
  WAITLIST_TRIBE_SELECTION_LABEL: 'Tribe selection',
  SLIDER_OPENER: 'sliderOpener',
  EXISTING_USER_ERROR: 'Existing User Error',
  EXPLORE_SESSION_FEEDBACK: 'exploreSessionFeedback',
  EXPLORE_SESSION_FEEDBACK_LABEL: 'Explore Session Feedback',
  SCHEDULE_SESSION_ACTIVITY: 'scheduleSessionActivity',
  ORDERS: 'orders',
  ORDERS_LABEL: 'Orders',
  TEACHER_LIST: 'teacherList',
  STUDENT_LIST: 'studentList',
  CHAT_MESSAGES: 'chatMessages',
  CHAT_MESSAGES_HEADER: 'chatMessagesHeader'
}

export const products = ['inventory', 'members_app', 'website']

export const DUMMY_PARENT_LABEL =
  'This is a paused/ churned member. Make them active without sending them a payment link'

export const USER_EXISTS_ERROR_MESSAGE =
  'This number already exists. Proceeding will delete the existing user and then update the number. Are you sure you want to proceed?'

export const FORCE_UPDATE_ERROR_MESSAGE =
  'This number cannot be updated as it already exists in the system. Do you wish to submit a deletion request?'

export const RECHARGE_REASON = {
  REFERRAL: 'Referral',
  REFUND: 'Refund',
  RECHARGE: 'Recharge'
}

export const POLICY_ACKNOWLEDGMENT_ALFRED =
  'I confirm that I’ve informed the parent about all the Openhouse policies (subscription, refund, make-up classes), and they have agreed.'

export const USER_STATUS = {
  NEW_LEAD: 'new',
  TRIAL_BOOKED: 'trial_booked',
  CUSTOMER: 'customer'
}

export const SUPPORT_FORM_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSe24o1oYeXbYSjki5lxiO1JpJU1Q2_96dh5a0LQNkOSDc-t4w/viewform'

export const EXCLUDE_FROM_OPENDAY_COURSE_ID = [
  '31101bc7-d9d0-4f30-af94-e4fbaec28fd2',
  'f5c14111-46c1-43c9-8b62-2f2efed9d35d'
]

export const TICKET_STATUS_OPTIONS = {
  TO_BE_PICKED: 'To be picked',
  IN_PROGRESS_CENTRE: 'In Progress Centre',
  IN_PROGRESS_CORPORATE: 'In Progress Corporate',
  RESOLVED: 'Resolved'
}

export const FOLLOW_UP_DATE_MESSAGE =
  'The follow-up date for this ticket is in future, and marking this as resolved will change it to today. Make sure that the follow-up was done with the parent.'

export const CARTV2_TYPE = {
  TOPUP: 'topup',
  TOPUP_BOOKING: 'topup_booking',
  BOOKING: 'booking'
}

export const SUBSCRIPTION_STATUSES = {
  DISCOVERY_UPCOMING: 'discovery_upcoming', //upcoming
  DISCOVERING: 'discovering', //active
  DISCOVERED: 'discovered', //inactive
  SUBSCRIPTION_UPCOMING: 'subscription_upcoming', //upcoming
  SUBSCRIBED: 'subscribed', //active
  PAUSED: 'paused', //active
  UNSUBSCRIBED: 'unsubscribed' //inactive
}

export const SUBSCRIPTION_STATUS_MAP = {
  active: [
    SUBSCRIPTION_STATUSES.DISCOVERING,
    SUBSCRIPTION_STATUSES.SUBSCRIBED,
    SUBSCRIPTION_STATUSES.PAUSED
  ],
  upcoming: [
    SUBSCRIPTION_STATUSES.DISCOVERY_UPCOMING,
    SUBSCRIPTION_STATUSES.SUBSCRIPTION_UPCOMING
  ],
  inactive: [
    SUBSCRIPTION_STATUSES.DISCOVERED,
    SUBSCRIPTION_STATUSES.UNSUBSCRIBED
  ]
}

export const OVERRIDE_18K_NUMBERS = [
  '7872761120', //SOUNAK
  '9654653076', //NIKHIL,
  '7717775805', //ARYAMAN,
  '9830072834' //AASTHA
]
