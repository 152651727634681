import { ThemeProvider } from '@material-ui/styles'
import React from 'react'
import ReactNotifications from 'react-notifications-component'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import ErrorBoundary from 'app/components/generic/ErrorBoundary'
import constants from 'app/constants'
import RouteHandler from 'app/routes/RouteHandler'
import { updateTheme } from 'app/store/actions/app'
import { dispatchFetchStates, dispatchFetchCities } from 'app/store/dispatcher'
import { store } from 'app/store/store'
import { loginWithCookie } from 'app/store/thunks/auth'
import { getTheme } from 'app/theme'

import 'app/App.css'
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.compat.css'
import dayjs from 'dayjs'

function App() {
  const theme = useSelector((state) => state.appReducer.theme)
  dayjs.locale('en-in')

  React.useEffect(() => {
    // check theme in localstorage if available
    const savedTheme = localStorage.getItem('theme')

    const {
      appReducer: { theme: currentTheme }
    } = store.getState()

    if (
      savedTheme &&
      [constants.LIGHT, constants.DARK].indexOf(savedTheme) > -1 &&
      savedTheme !== currentTheme
    ) {
      store.dispatch(updateTheme(savedTheme))
    }
  }, [])

  React.useEffect(() => {
    dispatchFetchStates()
    dispatchFetchCities()
    store.dispatch(loginWithCookie())
  }, [])

  return (
    <BrowserRouter>
      <ThemeProvider theme={getTheme(theme)}>
        <ErrorBoundary>
          <ReactNotifications />
          <RouteHandler />
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
