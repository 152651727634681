import { isEmpty } from 'lodash'

import { sentryLogger } from 'sentry'

import { makeRequestUrl } from '../app/helpers'
import { get, post } from '../request'

const mandatoryEnvironments = [
  'REACT_APP_API_BASE_URL',
  'REACT_APP_API_BASE_URL_V2',
  'REACT_APP_API_BASE_URL_V4'
]

mandatoryEnvironments.forEach((environment) => {
  if (!process.env[environment]) {
    console.error(`${environment} is not set in environment`)
  }
})

export const apiURLOrigin = process.env.REACT_APP_API_BASE_URL
export const apiURLOriginV2 = process.env.REACT_APP_API_BASE_URL_V2
export const apiURLOriginV4 = process.env.REACT_APP_API_BASE_URL_V4

export const apiEndpoints = {
  auth: `${apiURLOrigin}/auth`,
  addTribe: `${apiURLOrigin}/oh_clubs/add_tribe_member`,
  batch: `${apiURLOrigin}/oh_classes/batch`,
  fetchBatch: `${apiURLOrigin}/oh_classes/batch/alfred`,
  board: `${apiURLOrigin}/commons/board`,
  centre: `${apiURLOrigin}/commons/centre`,
  course: `${apiURLOrigin}/oh_classes/course`,
  cloudFileStorage: 'https://api.cloudinary.com/v1_1',
  club: `${apiURLOrigin}/oh_clubs/club`,
  clubRegistrations: `${apiURLOrigin}/oh_clubs/club/registration`,
  clubRegistrationConvert: `${apiURLOrigin}/oh_clubs/club/registration/convert`,
  tribeList: `${apiURLOrigin}/oh_clubs/tribe/club`,
  enrolment: `${apiURLOrigin}/oh_classes/enrolment`,
  trialSessions: `${apiURLOrigin}/oh_classes/attendance`,
  fetchEnrolment: `${apiURLOrigin}/oh_classes/enrolment`,
  event: `${apiURLOrigin}/oh_events`,
  growth: `${apiURLOrigin}/oh_growth`,
  ohClasses: `${apiURLOrigin}/oh_classes`,
  payment: `${apiURLOrigin}/oh_payments`,
  paymentInvoice: `${apiURLOrigin}/oh_payments/invoice/alfred`,
  coinTransaction: `${apiURLOrigin}/oh_payments/coin_transaction`,
  referralCode: `${apiURLOrigin}/oh_clubs/referral_code`,
  room: `${apiURLOrigin}/commons/room`,
  oh_schedules: `${apiURLOrigin}/oh_schedules`,
  schedule: `${apiURLOrigin}/oh_schedules/schedule`,
  standard: `${apiURLOrigin}/commons/standard`,
  school: `${apiURLOrigin}/commons/school`,
  subject: `${apiURLOrigin}/commons/subject`,
  subjectCategory: `${apiURLOrigin}/commons/subject_category`,
  configApp: `${apiURLOrigin}/config/app`,
  thirdparty: `${apiURLOrigin}/thirdparty`,
  teacher: `${apiURLOrigin}/oh_users/teacher`,
  tribe: `${apiURLOrigin}/oh_clubs/tribe`,
  user: `${apiURLOrigin}/oh_users`,
  journeyMentor: `${apiURLOrigin}/oh_journey_mentors`,
  common: `${apiURLOrigin}`,
  communication: `${apiURLOrigin}/oh_communications/communication`,
  createSibling: `${apiURLOrigin}/base/user`,
  student: `${apiURLOrigin}/oh_users/student`,
  superprovider: `${apiURLOrigin}/oh_users/superprovider`,
  createStudent: `${apiURLOrigin}/oh_users/create_student`,
  getStudentParent: `${apiURLOrigin}/oh_users/get_student_parent`,
  validateReferral: `${apiURLOrigin}/oh_users/parent/referral/validate`,
  waitlist: `${apiURLOrigin}/oh_clubs/waitlist`,
  website: `${apiURLOrigin}/oh_www`,
  mockTest: `${apiURLOrigin}/oh_mock_tests`,
  updateNotes: `${apiURLOrigin}/oh_clubs/tribe/membership/notes`,
  waitlistNotes: `${apiURLOrigin}/oh_clubs/waitlist/queue/notes`,
  portfolio: `${apiURLOrigin}/oh_portfolios`,
  constants: `${apiURLOrigin}/base/constants`,
  geography: `${apiURLOrigin}/commons/geography`,
  centreSchedules: `${apiURLOrigin}/oh_schedules/centre/schedule`,
  modules: `${apiURLOrigin}/oh_classes/course/module`,
  mindsets: `${apiURLOrigin}/oh_classes/course/mindset`,
  memberships: `${apiURLOrigin}/oh_memberships`,
  plans: `${apiURLOrigin}/oh_memberships/plans/`,
  active_plans: `${apiURLOrigin}/oh_memberships/plans/active`,
  subscriptions: `${apiURLOrigin}/oh_memberships/subscriptions/`,
  parent: `${apiURLOrigin}/oh_users/parent`,
  attendance: `${apiURLOrigin}/oh_classes/attendance/`,
  attendanceV2: `${apiURLOriginV2}/oh_classes/attendance/`,
  dashboardSchedules: `${apiURLOriginV4}/oh_schedules/schedule`,
  feedback: `${apiURLOrigin}/oh_feedback/feedback/`,
  batch_suggestion: `${apiURLOrigin}/oh_classes/course/batch-suggestion`,
  make_up_session: `${apiURLOrigin}/oh_schedules/make_up_session_suggestions`,
  journey: `${apiURLOrigin}/oh_journey/journey/`,
  moments: `${apiURLOrigin}/oh_moments/moment`,
  momentConsumer: `${apiURLOrigin}/oh_moments/moment_consumer`,
  cart: `${apiURLOrigin}/oh_cart`,
  actions: `${apiURLOrigin}/oh_actions/actions`,
  teacher: `${apiURLOrigin}/oh_teachers`,
  programs: `${apiURLOrigin}/oh_programs`,
  programEnrolment: `${apiURLOrigin}/oh_programs/enrolments`,
  googlePlaces: `${apiURLOrigin}/thirdparty/google_places`,
  addCoins: `${apiURLOrigin}/oh_memberships/topup/`,
  scheduleFeedback: `${apiURLOrigin}/oh_feedback/schedule_feedback`,
  openbook: `${apiURLOrigin}/oh_openbook/post`,
  openbookFiles: `${apiURLOrigin}/oh_files/file`,
  interactions: `${apiURLOrigin}/crm/interaction`,
  tickets: `${apiURLOrigin}/crm/ticket`,
  openday: `${apiURLOrigin}/oh_classes/showcase/schedule/`,
  opendaySlots: `${apiURLOrigin}/oh_classes/showcase/slot/`,
  studentProgressJourney: `${apiURLOrigin}/oh_student_progress/journey/`,
  progressReports: `${apiURLOrigin}/oh_student_progress/reports/`,
  abilitiesProgress: `${apiURLOrigin}/oh_student_progress/ability_progress/`,
  opportunities: `${apiURLOrigin}/commons/opportunity/`,
  certifications: `${apiURLOrigin}/commons/certification`,
  subscription: `${apiURLOrigin}/oh_student_progress/subscription/`,
  rechargePlans: `${apiURLOrigin}/oh_memberships/recharge_plans/`,
  transaction: `${apiURLOrigin}/oh_payments/transaction`,
  cartV2: `${apiURLOriginV2}/oh_payments/cart/`,
  coinCalculation: `${apiURLOrigin}/oh_bff/ma/coin_calculation/`,
  orders: `${apiURLOriginV2}/oh_payments/invoice`,
  teacherList: `${apiURLOrigin}/oh_chat/alfred/teachers/`,
  studentList: `${apiURLOrigin}/oh_chat/alfred/chats`,
  chatList: `${apiURLOrigin}/oh_chat/alfred/messages`,
}

export const validateUpdateRequest = (data) => {
  if (isEmpty(data.uuid)) {
    throw new Error('uuid is missing / invalid')
  }
}

export const validateOpendaySchedulePatchRequest = (data) => {
  if (Array.isArray(data.centre) && data?.centre?.length > 1) {
    throw new Error('multiple centres cannot be selected')
  }
}

export const validateDeleteRequest = (ids) => {
  if (!(Array.isArray(ids) && !isEmpty(ids))) {
    throw new Error('ids are missing / invalid')
  }
}

export const generateOtp = async (data) => {
  try {
    const response = await post(`${apiEndpoints.auth}/generate_otp/`, {
      phone_number: data.phone_number
    })

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in generate otp')
    throw error
  }
}

export const getIndianStates = async () => {
  try {
    const response = await get(`${apiEndpoints.constants}?name=states`)

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching Indian states')
    throw error
  }
}

export const fetchSchools = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(
      `${apiEndpoints.googlePlaces}/textsearch/?type=school&location=lat,long`,
      queries
    )

  try {
    const response = await get(requestUrl)

    return {
      ...response.data,
      results: [
        ...response.data.results.map((item) => ({
          ...item,
          label: isEmpty(item.area) ? item.name : `${item.name} | ${item.area}`
        }))
      ]
    }
  } catch (error) {
    sentryLogger(error, 'error in fetching schools')
    throw error
  }
}

export const createSchool = async (data) => {
  try {
    const response = await post(`${apiEndpoints.common}/commons/school`, {
      name: data.name,
      locality: data.locality
    })

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating board')
    throw error
  }
}

export const getConfigValue = async (queries) => {
  try {
    const requestUrl = makeRequestUrl(apiEndpoints.configApp, queries)
    const response = await get(requestUrl)
    return response.data.results[0].value
  } catch (error) {
    sentryLogger(error, 'error in fetching config value')
    throw error
  }
}
