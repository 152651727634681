import styled from 'styled-components';

export const SelectedCardContainer = styled.div`
  border: ${({ isSelected }) => isSelected ? '2px solid #CEE8FF' : 'none'};
  border-bottom: ${({ isSelected, lastListItem }) =>
    lastListItem
      ? isSelected
        ? '2px solid #CEE8FF'
        : 'none'
      : isSelected
        ? '2px solid #CEE8FF'
        : '1px solid #ccc'
  };
  background-color: ${({ isSelected }) => isSelected ? '#EEF7FF' : 'none'};
  border-radius: ${({ isSelected }) => isSelected ? '8px' : 'none'};
`

export const InnerCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
`