/* eslint-disable react/prop-types */
import { InputLabel, MenuItem } from '@material-ui/core'
import { Field, useFormikContext } from 'formik'
import { get } from 'lodash'
import React, { useState } from 'react'

import { Select, TextField } from 'app/components/generic/FormElements'
import CloseIcon from 'assets/images/close.svg'

import {
  Container,
  Typography,
  RedirectCTA,
  Button,
  FormControl,
  FieldGroup,
  FieldContainer,
  Icon,
  FormHelperText
} from './styles'

import CloudFileUploader from '../CloudFileUploader'

const NestedFieldWidget = ({ fieldData, ...props }) => {
  const { config, setFieldValue, formikFieldData, fieldName } = props
  const fieldGroup = config.fields.reduce((acc, field) => {
    acc[field.name] = field.initialValue || ''
    return acc
  }, {})

  const [fieldValueData, setFieldValueData] = useState(
    fieldData ? fieldData : [fieldGroup]
  )

  React.useEffect(() => {
    setFieldValue(fieldName, [...fieldValueData])
  }, [])

  const addField = () => {
    const newData = [...fieldValueData, { ...fieldGroup }]
    setFieldValueData(newData)
    setFieldValue(fieldName, newData)
  }

  const removeField = (index) => {
    const newData = [...fieldValueData]
    newData.splice(index, 1)
    setFieldValueData(newData)
    setFieldValue(fieldName, newData)
  }

  const changeHandler = (index, field, value) => {
    const newData = [...fieldValueData]
    newData[index][field] = value
    setFieldValueData(newData)
    setFieldValue(fieldName, newData)
  }

  const handleFileUploadOnChange = (uploadData, index) => {
    const { name, data } = uploadData

    changeHandler(index, name, data)
  }

  const { errors, validateField } = useFormikContext()
  const formElement = React.useMemo(() => {
    return (
      <Container>
        {config.widgetTitle && (
          <Typography variant="h5" $css={`margin-bottom: 15px;`}>
            {config.widgetTitle}
          </Typography>
        )}

        {fieldValueData.map((field, index) => (
          <FieldContainer key={index}>
            <FieldGroup>
              {config.fields.map((fieldConfig) => {
                const fieldPath = `${fieldName}[${index}].${fieldConfig.name}`
                const fieldError = get(errors, fieldPath)

                if (fieldConfig.type === 'select') {
                  return (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>{fieldConfig.label}</InputLabel>
                      <Select
                        name={fieldPath}
                        label={fieldConfig.label}
                        value={field[fieldConfig.name]}
                        onChange={(e) => {
                          changeHandler(index, fieldConfig.name, e.target.value)
                          validateField(fieldPath)
                        }}
                        {...(typeof fieldConfig.validate === 'function'
                          ? {
                              validate: fieldConfig.validate({
                                isRequired: !fieldConfig?.optional
                              })
                            }
                          : null)}
                        required={!fieldConfig?.optional}
                        error={Boolean(fieldError)}
                        helperText={fieldError ? fieldError : ''}>
                        {Object.entries(fieldConfig.valueLabelMappings)
                          .filter(([value, label]) =>
                            fieldValueData.every((field, idx) => {
                              // Filter out if this value is already selected in another field
                              if (idx !== index) {
                                return fieldConfig.uniqueOption === true //Filtering happens only when the field has uniqueConfig set to true
                                  ? field[fieldConfig.name] !== value
                                  : true
                              }
                              return true
                            })
                          )
                          .map(([value, label], idx) => (
                            <MenuItem key={idx} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                      </Select>
                      {fieldError && (
                        <FormHelperText>{fieldError}</FormHelperText>
                      )}
                    </FormControl>
                  )
                } else if (fieldConfig.type === 'fileupload') {
                  return (
                    <FormControl
                      variant="outlined"
                      key={index}
                      required={!fieldConfig?.optional}
                      fullWidth>
                      <Field
                        name={fieldConfig.name}
                        component={({ field, form, ...props }) => (
                          <>
                            <CloudFileUploader
                              {...field}
                              {...props}
                              {...form}
                              fileUrlOnly={fieldConfig.fileUrlOnly}
                              name={fieldConfig.name}
                              label={fieldConfig.label}
                              onUpload={(data) =>
                                handleFileUploadOnChange(data, index)
                              }
                              uploadedFileUrl={
                                fieldValueData[index][fieldConfig.name]?.url
                              }
                              uploadPreset={fieldConfig.uploadPreset}
                              useNewMediaUpload={fieldConfig.newMedia || false}
                            />
                            <FormHelperText>
                              {form.errors[fieldConfig.name]}
                            </FormHelperText>
                          </>
                        )}
                        {...(typeof fieldConfig.validate === 'function'
                          ? {
                              validate: fieldConfig.validate({
                                isRequired: !fieldConfig.optional
                              })
                            }
                          : null)}
                      />
                    </FormControl>
                  )
                } else {
                  return (
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        name={fieldPath}
                        required={!fieldConfig?.optional}
                        variant="outlined"
                        label={fieldConfig.label}
                        value={field[fieldConfig.name]}
                        onChange={(e) =>
                          changeHandler(index, fieldConfig.name, e.target.value)
                        }
                        {...(typeof fieldConfig.validate === 'function'
                          ? {
                              validate: fieldConfig.validate({
                                isRequired: !fieldConfig?.optional
                              })
                            }
                          : null)}
                        {...(fieldConfig.maxCharacters
                          ? {
                              inputProps: {
                                ...{ maxLength: fieldConfig.maxCharacters }
                              }
                            }
                          : null)}
                      />
                    </FormControl>
                  )
                }
              })}
            </FieldGroup>

            {/* If more than one field, then show close icon */}
            {fieldValueData.length > 1 ? (
              <Icon
                src={CloseIcon}
                alt="close"
                onClick={() => removeField(index)}
              />
            ) : null}
          </FieldContainer>
        ))}

        <RedirectCTA>
          <Button variant="outlined" onClick={addField}>
            {config.ctaLabel}
          </Button>
        </RedirectCTA>
      </Container>
    )
  }, [config, setFieldValue, formikFieldData, fieldName, fieldValueData])

  return <>{formElement}</>
}

export default NestedFieldWidget
