import React from 'react'
import * as styles from './styles'

const UnreadCount = ({ unreadCount, unreadCountStyles }) => {

  return (
    unreadCount ? <div style={{ ...styles.Count, ...unreadCountStyles }}>{unreadCount}</div> : null
  )
}

export default UnreadCount