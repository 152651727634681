import { isEmpty } from 'lodash'
import { apiEndpoints } from './common'
import { makeRequestUrl } from '../app/helpers'
import { get } from '../request'


const fetchTeacherList = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.teacherList, queries)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log('error in fetching teacher list:- ', error.message)
    throw error
  }
}

const fetchStudentList = async ({ url, queries } = {}) => {

  const { teacher_uuid, ...restQueries } = queries || {}

  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.studentList}/${teacher_uuid}/`, restQueries)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log('error in fetching student list:- ', error.message)
    throw error
  }
}

const fetchChatMessages = async ({ url, queries } = {}) => {

  const { chat_uuid, ...restQueries } = queries || {}

  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.chatList}/${chat_uuid}/`, restQueries)

  try {
    const response = await get(requestUrl)
    return response.data
  } catch (error) {
    console.log('error in fetching chat messages:- ', error.message)
    throw error
  }
}

export { fetchTeacherList, fetchStudentList, fetchChatMessages }
