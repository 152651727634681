import React from 'react'
import * as styles from './styles'
import { Tooltip } from '@material-ui/core'

const Pill = ({ url, text, pillStyles, isSelected, truncationLength }) => {

  const isTextLong = text?.length > truncationLength
  const truncatedText = isTextLong ? `${text.substring(0, truncationLength).trim()}...` : text

  const pillContent = (
    <div
      style={{
        ...styles.PillStyles,
        ...pillStyles,
        ...(isSelected && styles.SelectedPillStyle),
      }}
    >
      <styles.Icon src={url} />
      {truncatedText}
    </div>
  )

  return isTextLong ? (
    <Tooltip title={text} arrow>
      {pillContent}
    </Tooltip>
  ) : (
    pillContent
  )
};

export default Pill
