
export const AvatarImageStyles = {
  height: '2rem',
  width: '2rem',
  borderRadius: '50%',
  flexShrink: 0
}

export const AvatarTextStyles = {
  width: '2rem',
  height: '2rem',
  backgroundColor: '#979797',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: '700',
  borderRadius: '50%',
  textTransform: 'uppercase',
  flexShrink: 0
}